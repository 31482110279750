import { isAxiosError } from 'axios';

import { camelCase } from 'utils/casing';
import { omitNullable } from 'utils/omit-nullable';
import { comparableString } from 'utils/string';
import { isIterableObject, isString } from 'utils/type-checks';

export interface ExtractResponseErrorsOptions {
  /** @default true */
  ensurePunctuation?: boolean;
}

export function extractResponseErrors(arg: unknown, options: ExtractResponseErrorsOptions = {}): ErrorT[] {
  const { ensurePunctuation = true } = options;

  const data = isAxiosError<unknown>(arg) ? arg.response?.data : arg;

  if (!isIterableObject(data)) return [];

  let errors: unknown;
  if (!errors && 'errors' in data) errors = data.errors;
  if (!errors && 'error' in data) errors = data.error;
  if (!errors) errors = [];

  const errorsArray: unknown[] = Array.isArray(errors) ? errors : errors ? [errors] : [];

  return omitNullable(
    errorsArray.map<ErrorT | null>((error) => {
      if (!error) return null;

      if (isString(error)) return { title: error };

      if (!isIterableObject(error)) return null;

      let title: string | undefined;
      let detail: string | undefined;
      let source: string | undefined;

      if ('title' in error && isString(error.title)) title = error.title;
      if ('detail' in error && isString(error.detail)) detail = error.detail;
      if ('source' in error && isString(error.source)) source = error.source;

      if (!title && !detail) return null;
      if (!title && detail) [title, detail] = [detail, title];
      if (title && detail && comparableString(title) === comparableString(detail)) detail = undefined;

      if (ensurePunctuation && title && !/[.!?]$/.test(title)) title += '.';
      if (ensurePunctuation && detail && !/[.!?]$/.test(detail)) detail += '.';

      const errorT: ErrorT = { title };
      if (detail) errorT.detail = detail;
      if (source) errorT.source = source;

      return errorT;
    }),
  );
}

export function camelizeErrorSource(error: ErrorT): ErrorT {
  const result = { ...error };
  if (result.source) result.source = camelCase(result.source);

  return result;
}
