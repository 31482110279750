import { ComponentType, lazy, LazyExoticComponent } from 'react';

type LazyComponentExports<Mod> = {
  [Name in keyof Mod as Mod[Name] extends ComponentType<any> ? Name : never]: Mod[Name] extends ComponentType<any>
    ? LazyExoticComponent<Mod[Name]>
    : never;
};

export function namedLazy<Mod>(factory: () => Promise<Mod>): LazyComponentExports<Mod> {
  return new Proxy(
    {},
    {
      get: (_target, name) =>
        lazy(() =>
          factory()
            .then((mod) => ({ default: mod[name] }))
            .catch((error) => {
              if (error instanceof Error) {
                // Re-throw error with a name that can be checked more easily in our ErrorBoundary component
                const dynamicImportError = new Error(error.message, { cause: error });
                dynamicImportError.name = 'DynamicImportError';

                throw dynamicImportError;
              }

              return error;
            }),
        ),
    },
  ) as any;
}
