import { useEffect } from 'react';

import { Storage } from 'utils/storage';

import { Icon } from 'components/icon';

export function isRackMiniProfilerEnabled() {
  return import.meta.env.DEV && import.meta.env.REACT_APP_RACK_MINI_PROFILER === '1';
}

export function RackMiniProfiler() {
  return isRackMiniProfilerEnabled() ? <RackMiniProfilerBadge /> : null;
}

// Persist state even when reloading
const SHOW_RACK_MINI_PROFILER = Storage.key<boolean>('dev_rack_mini_profiler_open');

function RackMiniProfilerBadge() {
  useEffect(() => {
    if (!document.head.querySelector('script#mini-profiler')) loadMiniProfiler();
  }, []);

  return (
    <Icon onClick={toggleElement} className="mini-profiler-badge" title="Toggle Rack Mini Profiler results" size={36}>
      diamond
    </Icon>
  );
}

function toggleElement() {
  Storage.local.set(SHOW_RACK_MINI_PROFILER, !(Storage.local.get(SHOW_RACK_MINI_PROFILER) || false));

  const result = document.body.querySelector<HTMLButtonElement>('.profiler-results');
  if (result) result.style.display = result.style.display === 'none' ? 'block' : 'none';
}

function loadMiniProfiler() {
  const startHidden = String(Storage.local.get(SHOW_RACK_MINI_PROFILER) || false);

  const script = document.createElement('script');
  script.async = true;
  script.src = '/mini-profiler-resources/includes.js?v=3.1.0-fork_fix';
  script.type = 'text/javascript';
  script.id = 'mini-profiler';
  script.setAttribute('data-css-url', '/mini-profiler-resources/includes.css?v=3.2.0');
  script.setAttribute('data-version', '3.2.0');
  script.setAttribute('data-path', '/mini-profiler-resources/');
  script.setAttribute('data-horizontal-position', 'right');
  script.setAttribute('data-vertical-position', 'bottom');
  script.setAttribute('data-ids', '');
  script.setAttribute('data-trivial', 'false');
  script.setAttribute('data-children', 'false');
  script.setAttribute('data-max-traces', '20');
  script.setAttribute('data-controls', 'false');
  script.setAttribute('data-total-sql-count', 'false');
  script.setAttribute('data-authorized', 'true');
  script.setAttribute('data-toggle-shortcut', 'Alt+P');
  script.setAttribute('data-start-hidden', startHidden);
  script.setAttribute('data-collapse-results', 'false');
  script.setAttribute('data-hidden-custom-fields', '');
  script.setAttribute('data-html-container', 'body');
  document.head.appendChild(script);
}
