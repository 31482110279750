type CustomStorageEvent<T> = Omit<StorageEvent, 'key' | 'newValue' | 'oldValue'> & {
  key: string;
  newValue: T | null;
  oldValue: T | null;
};

type StorageKey<T> = string & Brand<'storage_key', T>;

class StorageHelper {
  #storage: Storage;
  #userId: string | undefined;

  constructor(storage: Storage = localStorage) {
    this.#storage = storage;
  }

  forUser(userId: string) {
    this.#userId = userId;

    return this;
  }

  get<T>(key: StorageKey<T>) {
    return decodeStorageValue<T>(this.getRaw(key));
  }

  set<T>(key: StorageKey<T>, value: T) {
    this.setRaw(key, encodeStorageValue(value));
  }

  getRaw<T>(key: StorageKey<T>) {
    const value = this.#storage.getItem(this.#createKey(key));

    if (value === null && this.#userId) {
      return this.#storage.getItem(this.#createKey(key, true));
    }

    return value;
  }

  setRaw<T>(key: StorageKey<T>, value: string) {
    this.#storage.setItem(this.#createKey(key), value);
  }

  remove<T>(key: StorageKey<T>) {
    this.#storage.removeItem(this.#createKey(key));
  }

  clear() {
    this.#storage.clear();
  }

  listen<T>(key: StorageKey<T>, callback: (event: CustomStorageEvent<T>) => void) {
    const listener = (event: StorageEvent) => {
      if (event.storageArea === this.#storage && event.key === this.#createKey(key) && event.isTrusted) {
        const customEvent: CustomStorageEvent<T> = {
          ...event,
          key,
          newValue: decodeStorageValue<T>(event.newValue),
          oldValue: decodeStorageValue<T>(event.oldValue),
        };

        callback(customEvent);
      }
    };

    window.addEventListener('storage', listener);

    return function cleanup() {
      window.removeEventListener('storage', listener);
    };
  }

  #createKey<T>(key: StorageKey<T>, ignoreUser = false) {
    if (this.#userId && !ignoreUser) return `__nf_${this.#userId}_${key}__`;

    return `__nf_${key}__`;
  }
}

export const Storage = {
  get local() {
    return new StorageHelper(localStorage);
  },
  get session() {
    return new StorageHelper(sessionStorage);
  },
  key<T>(key: string) {
    return key as StorageKey<T>;
  },
};

function encodeStorageValue(value: any) {
  return JSON.stringify(value);
}

function decodeStorageValue<T>(json: string | null) {
  if (json === null) return null;

  let decoded: T | null = null;
  try {
    decoded = JSON.parse(json);
  } catch (error) {}

  return decoded;
}
