import { PublicClientApplication } from '@azure/msal-browser';
import axios from 'axios';

import { apiUrl } from 'api/url';

import { camelCaseKeys } from 'utils/casing';
import { getUserTimeZone } from 'utils/date';

import { AuthStrategy, useAuthenticationStrategy } from 'hooks/use-config';

import { forgetToken, persistToken, retrieveToken } from './token-storage';
import { AuthCredentials, Authenticator, UserToken } from './types';

class MicrosoftAuthenticator implements Authenticator {
  msalInstance = new PublicClientApplication({
    auth: {
      authority: import.meta.env.REACT_APP_MSAL_AUTHORITY,
      clientId: import.meta.env.REACT_APP_MSAL_CLIENT_ID || '',
    },
    cache: {
      cacheLocation: 'localStorage',
      cacheMigrationEnabled: true,
    },
  });

  async login(): Promise<UserToken> {
    await this.msalInstance.initialize();

    const authenticationResult = await this.msalInstance.loginPopup({
      scopes: ['email'],
    });

    return authenticate({ id_token: authenticationResult.idToken });
  }

  async logout(): Promise<void> {
    await invalidateToken();
    this.msalInstance.logoutRedirect();
  }
}

class UsernamePasswordAuthenticator implements Authenticator {
  login(credentials: AuthCredentials): Promise<UserToken> {
    return authenticate(credentials);
  }

  async logout(): Promise<void> {
    await invalidateToken();
  }
}

async function authenticate(credentials: AuthCredentials): Promise<UserToken> {
  try {
    const response = await axios.post<UserToken>(apiUrl('auth'), credentials, {
      headers: {
        'X-User-Timezone': getUserTimeZone(),
        'Content-Type': 'application/json',
      },
    });

    const data = camelCaseKeys(response.data) as UserToken;

    persistToken(data);

    return data;
  } catch (error: any) {
    const { response } = error;

    forgetToken();

    if (response.status === 401) {
      throw new Error(response.data.errors?.[0]?.title ?? response.statusText);
    }

    throw new Error(response.statusText);
  }
}

async function invalidateToken() {
  const { refresh } = retrieveToken();

  try {
    await axios.delete(apiUrl('auth'), {
      headers: {
        'X-User-Timezone': getUserTimeZone(),
        'X-Refresh-Token': `Bearer ${refresh}`,
      },
    });
  } catch (error) {}

  forgetToken();
}

let authenticatorInstance: MicrosoftAuthenticator | UsernamePasswordAuthenticator | undefined;

function getAuthenticatorInstance(authStrategy: AuthStrategy) {
  if (authStrategy === 'oidc' && !(authenticatorInstance instanceof MicrosoftAuthenticator)) {
    authenticatorInstance = new MicrosoftAuthenticator();
  }

  if (authStrategy !== 'oidc' && !(authenticatorInstance instanceof UsernamePasswordAuthenticator)) {
    authenticatorInstance = new UsernamePasswordAuthenticator();
  }

  if (!authenticatorInstance) {
    authenticatorInstance = new UsernamePasswordAuthenticator();
  }

  return authenticatorInstance;
}

export function useAuthenticator(): Authenticator {
  const authStrategy = useAuthenticationStrategy();

  return getAuthenticatorInstance(authStrategy);
}
