import { Storage } from 'utils/storage';

import { UserToken } from './types';

export const USER_TOKEN_STORAGE_KEY = Storage.key<UserToken>('user_token');

export function retrieveToken() {
  return Storage.local.get(USER_TOKEN_STORAGE_KEY) || {};
}

export function persistToken(token: UserToken, merge = false) {
  const data = merge ? { ...retrieveToken(), ...token } : token;
  Storage.local.set(USER_TOKEN_STORAGE_KEY, data);
}

export function forgetToken() {
  Storage.local.remove(USER_TOKEN_STORAGE_KEY);
}
