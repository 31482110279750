import { parse, stringify } from 'qs';

import { isIsoDate } from 'utils/date';

function decode(queryString: string): Record<string, unknown> {
  return parse(queryString, {
    ignoreQueryPrefix: true,
    decoder: (value) => {
      if (['true', 'false', 'null'].includes(value)) {
        return JSON.parse(value);
      }
      if (value === '[]' || value === encodeURI('[]')) {
        return [];
      }
      if (/^(0|[1-9]\d*)(\.\d+)?$/.test(value)) {
        return parseFloat(value);
      }

      let decodedValue = value.replace(/\+/g, ' ');
      try {
        decodedValue = decodeURIComponent(decodedValue);
      } catch (error) {
        if (import.meta.env.DEV) {
          // eslint-disable-next-line no-console
          console.error('Failed to decodeURIComponent:', error);
        }
      }

      if (isIsoDate(decodedValue)) {
        return new Date(decodedValue);
      }

      return decodedValue;
    },
  });
}

function encode(object: Record<string, any>): string {
  const modifiedObj = Object.fromEntries(
    Object.entries(object).map(([key, value]) => {
      if ([true, false, null].includes(value)) {
        return [key, JSON.stringify(value)];
      }
      if (Array.isArray(value) && !value.length) {
        return [key, '[]'];
      }

      return [key, value];
    }),
  );

  return stringify(modifiedObj, {
    arrayFormat: 'brackets',
  });
}

export const QS = {
  decode,
  encode,
};
