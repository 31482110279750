import { ReactElement } from 'react';

import { defineMessages, FormattedMessage, useIntl } from 'intl';

import { NavLink } from 'router';

import { Button } from 'components/button';
import { Icon } from 'components/icon';

import logoType from 'assets/images/logo-type.png';

export function ErrorPage({ onReport }: { onReport: () => void }) {
  return (
    <PageWrapper>
      <div className="error-page__container">
        <h2 className="error-page__title -is-shaking font-t3">
          <Icon>flash_on</Icon>
          <br />
          <FormattedMessage {...t.errorHeadline} />
        </h2>

        <br />

        <Button meaning="affirmative" onClick={() => window.location.assign(window.location.origin)}>
          {t.reloadApp}
        </Button>

        <Button meaning="affirmative" importance="secondary" title={t.reportError} onClick={onReport}>
          {t.reportError}
        </Button>
      </div>
    </PageWrapper>
  );
}

export function StaleModuleCacheErrorPage() {
  return (
    <PageWrapper>
      <div className="error-page__container">
        <h2 className="error-page__title -is-beating font-t3">
          <Icon>celebration</Icon>
          <br />
          <FormattedMessage {...t.staleModuleCacheErrorHeadline} />
        </h2>

        <br />

        <Button meaning="affirmative" onClick={() => window.location.reload()}>
          {t.reloadApp}
        </Button>
      </div>
    </PageWrapper>
  );
}

export function ForbiddenPage() {
  const { formatMessage } = useIntl();

  return (
    <PageWrapper>
      <div className="error-page__container">
        <h2 className="error-page__title -is-beating font-t3">
          <Icon>security</Icon>
          <br />
          <FormattedMessage {...t.forbiddenHeadline} />
        </h2>

        <br />

        <NavLink to="/" title={formatMessage(t.backHomeTitle)}>
          <Button meaning="affirmative">{t.backHome}</Button>
        </NavLink>
      </div>
    </PageWrapper>
  );
}

export function NotFoundPage() {
  const { formatMessage } = useIntl();

  return (
    <PageWrapper>
      <div className="error-page__container">
        <h2 className="error-page__title -is-shaking font-t3">
          <Icon>location_searching</Icon>
          <br />
          <FormattedMessage {...t.notFoundHeadline} />
        </h2>
        <br />

        <NavLink to="/" title={formatMessage(t.backHomeTitle)}>
          <Button meaning="affirmative">{t.backHome}</Button>
        </NavLink>
      </div>
    </PageWrapper>
  );
}

function PageWrapper({ children }: { children?: ReactElement }) {
  return (
    <div className="error-page">
      {children}
      <img src={logoType} className="error-page__logo" alt="NephroFlow" />
    </div>
  );
}

const t = defineMessages({
  errorHeadline: {
    id: 'error_page_error_headline',
    defaultMessage: 'Oops, looks like an error has occurred.',
  },
  staleModuleCacheErrorHeadline: {
    id: 'error_page_stale_module_cache_error_headline',
    defaultMessage: 'A newer version of NephroFlow Manager is available.',
  },
  notFoundHeadline: {
    id: 'error_page_not_found_headline',
    defaultMessage: 'Oops, looks like the page you were looking for cannot be found.',
  },
  forbiddenHeadline: {
    id: 'error_page_forbidden_headline',
    defaultMessage: 'Oops, looks like you are not allowed to view this page.',
  },
  reloadApp: {
    id: 'error_page_refresh_state',
    defaultMessage: 'Reload the app',
  },
  reportError: {
    id: 'error_page_report_title',
    defaultMessage: 'Report error',
  },
  backHome: {
    id: 'error_page_back_home',
    defaultMessage: 'Bring me back home',
  },
  backHomeTitle: {
    id: 'error_page_back_home_title',
    defaultMessage: 'Back to dashboard',
  },
});
