/* eslint-disable ndte/intl-imports */

import { useQueryClient } from '@tanstack/react-query';

import { Capabilities, CAPABILITIES_QUERY_KEY, useCapabilities } from 'hooks/use-config';

export function useAppLocale() {
  const { capabilities } = useCapabilities();

  // i.e. "es_419", "zh_CN", there cannot be multiple dashes like "zh-Hant-CN" which is supported in the browser.
  // see https://github.com/svenfuchs/rails-i18n?tab=readme-ov-file#available-locales-1 for supported locales in rails.
  // although docs show a dash it comes back as "_" from API
  const locale = capabilities?.locale?.replace('_', '-');
  const lang = locale?.split('-')[0];
  const country = capabilities?.configurations.country;

  if (locale && locale.indexOf('-') !== -1 && isSupportedLocale(locale)) return locale;
  // continue with base language if full locale is not supported i.e "es", "zh"
  if (lang && country && isSupportedLocale(`${lang}-${country}`)) return `${lang}-${country}`;
  if (lang && isSupportedLocale(lang)) return lang;

  return 'en-GB';
}

export function useUpdateCapabilitiesLocale() {
  const queryClient = useQueryClient();

  return (locale: string) => {
    queryClient.setQueryData<Capabilities>(CAPABILITIES_QUERY_KEY, (capabilities) => {
      if (capabilities) return { ...capabilities, locale };
    });
  };
}

function isSupportedLocale(locale: string) {
  // "supportedLocalesOf" will exit with an error if a non-existing language tag is passed
  try {
    const supportsNumberFormat = !!Intl.NumberFormat.supportedLocalesOf(locale).length;
    const supportsDateTimeFormat = !!Intl.DateTimeFormat.supportedLocalesOf(locale).length;

    return supportsNumberFormat && supportsDateTimeFormat;
  } catch (error) {
    // eslint-disable-next-line no-console
    if (import.meta.env.DEV) console.warn(error);

    return false;
  }
}
