import { Action } from 'redux';

import { CANCEL_ACTIVITY, START_ACTIVITY } from 'store/modules/activity';

import { getType } from 'utils/get-type';

// Reducer
//======================================================================================================================

export interface SuccessState {
  type?: string;
  [key: string]: any;
}

interface SuccessAction extends Action<string> {
  payload: any;
}

export const initialState = {};

export function successReducer(state: SuccessState = initialState, action: SuccessAction): SuccessState {
  const { type } = action;

  switch (true) {
    case type === CANCEL_ACTIVITY:
    case type === START_ACTIVITY:
    case type.endsWith('FAILURE'): {
      return initialState;
    }

    // Save entire payload for success without handler
    case type.endsWith('SUCCESS'): {
      const { type, payload } = action;

      return { type: getType(type), payload };
    }

    default: {
      return state;
    }
  }
}
