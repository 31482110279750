import * as sentry from '@sentry/browser';
import { Component, ErrorInfo, ReactNode } from 'react';

import { ErrorPage, StaleModuleCacheErrorPage } from 'views/components/error-pages';

const CHUNK_LOAD_ERROR = 'ChunkLoadError';
const DYNAMIC_IMPORT_ERROR = 'DynamicImportError';

export class ErrorBoundary extends Component<{ children: ReactNode }, { hasError: boolean }> {
  state = {
    hasError: false,
    isChunkLoadError: false,
    isDynamicImportError: false,
  };

  /**
   * @public (https://knip.dev/reference/jsdoc-tsdoc-tags/#public)
   */
  static getDerivedStateFromError(error: Error) {
    const hasError = true;
    const isChunkLoadError = error.name === CHUNK_LOAD_ERROR;
    const isDynamicImportError = error.name === DYNAMIC_IMPORT_ERROR;

    return {
      hasError,
      isChunkLoadError,
      isDynamicImportError,
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    switch (error.name) {
      case CHUNK_LOAD_ERROR:
      case DYNAMIC_IMPORT_ERROR:
        break;

      default:
        this.reportError(error, errorInfo);
        break;
    }
  }

  reportError(error: Error, errorInfo: ErrorInfo) {
    sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });

      sentry.captureException(error);
    });
  }

  render() {
    const { children } = this.props;
    const { hasError, isChunkLoadError, isDynamicImportError } = this.state;

    if (!hasError) {
      return children;
    }

    if (isChunkLoadError || isDynamicImportError) {
      return <StaleModuleCacheErrorPage />;
    }

    return (
      <ErrorPage
        onReport={() => {
          if (import.meta.env.DEV) {
            window.alert('Error reported!');
          } else {
            sentry.showReportDialog();
          }
        }}
      />
    );
  }
}
