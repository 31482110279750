import { useQuery, useQueryClient } from '@tanstack/react-query';

import { http } from 'api/client';

import { ConfigurationKey } from 'hooks/use-config';

export type ConfigurationValue = null | string | number | boolean | string[];

export type ConfigurationTag = NonNullable<Configuration['tag']>;

export interface Configuration {
  id: string;
  type: 'string' | 'integer' | 'float' | 'boolean' | 'list';
  value?: ConfigurationValue | null;
  default?: ConfigurationValue | null;
  key: ConfigurationKey;
  title: string;
  description: string;
  dependencies: string[];
  dependenciesSatisfied: boolean;
  allowedValues: OptionT[];
  required: boolean;
  tag?:
    | 'assistant'
    | 'auth_policy'
    | 'background_tasks'
    | 'contacts'
    | 'context_links'
    | 'debugging'
    | 'diets'
    | 'encounters'
    | 'export_center'
    | 'exports'
    | 'infra_centers'
    | 'infra_devices'
    | 'integration_flows_dis'
    | 'integration_flows_emr'
    | 'integration_flows_his'
    | 'integration_flows_lis'
    | 'integration_flows_mi'
    | 'integration_flows_nes'
    | 'integration_flows_ps'
    | 'ktv_calculations'
    | 'letter_builder'
    | 'materiovigilance'
    | 'medication_mngmt'
    | 'notification_center'
    | 'patient_mngmt'
    | 'planning'
    | 'report_templates'
    | 'session_mngmt'
    | 'system_basic'
    | 'system_modules'
    | 'transportation'
    | 'treatment_prescription'
    | 'treatment_preset'
    | 'treatment_types'
    | 'vascular_access'
    | 'workflow_tasks'
    | 'wound_care';
}

async function fetchConfigurations() {
  const { data } = await http.get<{ configurations: Configuration[] }>('configurations');

  return data.configurations;
}

export async function updateConfiguration({ id, value }: { id: string; value: ConfigurationValue }) {
  const { data } = await http.put<{ configuration: Configuration }>(`configurations/${id}`, {
    configuration: { value },
  });

  return data.configuration;
}

export const CONFIGURATIONS_QUERY_KEY = ['configurations'];

export function useConfigurations() {
  const { data: configurations, isLoading } = useQuery({
    queryKey: CONFIGURATIONS_QUERY_KEY,
    queryFn: () => fetchConfigurations(),
  });

  return { configurations, isLoading };
}

export function useUpdateConfigurationItem() {
  const queryClient = useQueryClient();

  return (id: string, value: ConfigurationValue | null) => {
    queryClient.setQueryData<Configuration[]>(CONFIGURATIONS_QUERY_KEY, (configurations) => {
      if (!configurations) return undefined;

      return configurations.map((config) => (config.id === id ? { ...config, value } : config));
    });
  };
}

export function useGetConfigurationItemKey() {
  const queryClient = useQueryClient();

  return (id: string) => {
    return queryClient.getQueryData<Configuration[]>(CONFIGURATIONS_QUERY_KEY)?.find((config) => config.id === id)?.key;
  };
}
