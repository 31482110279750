import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { assert } from 'utils/assert';
import { Storage } from 'utils/storage';

import { useSyncedRef } from 'hooks/use-synced-ref';

import { useCurrentUser } from './current-user-provider';

const WIDE_SIDEBAR_STORAGE_KEY = Storage.key<boolean>('wide_sidebar');

interface AppState {
  sidebar: {
    isWide: boolean;
    canToggle: boolean;
  };
}

const initialState = (isWide = false) => ({
  sidebar: {
    isWide,
    canToggle: true,
  },
});

const AppStateContext = createContext<{ appState: AppState; setAppState: Dispatch<SetStateAction<AppState>> }>({
  appState: initialState(),
  setAppState: () => {},
});

AppStateContext.displayName = 'AppStateContext';

export function AppStateProvider({ children }: { children: ReactNode }) {
  const currentUser = useCurrentUser();
  const userIdRef = useSyncedRef(currentUser.id);

  const [appState, setAppState] = useState(() => {
    const isWide = Storage.local.forUser(userIdRef.current).get(WIDE_SIDEBAR_STORAGE_KEY) ?? false;

    return initialState(isWide);
  });

  useEffect(() => {
    Storage.local.forUser(userIdRef.current).set(WIDE_SIDEBAR_STORAGE_KEY, appState.sidebar.isWide);
  }, [appState.sidebar.isWide, userIdRef]);

  return <AppStateContext.Provider value={{ appState, setAppState }}>{children}</AppStateContext.Provider>;
}

export function useAppState() {
  const context = useContext(AppStateContext);

  assert(context, 'The hook `useAppState` must be used within an `AppStateProvider`.');

  const { appState, setAppState } = context;

  const expandSidebar = useCallback(
    () => setAppState((state) => ({ ...state, sidebar: { ...state.sidebar, isWide: true } })),
    [setAppState],
  );

  const collapseSidebar = useCallback(
    () => setAppState((state) => ({ ...state, sidebar: { ...state.sidebar, isWide: false } })),
    [setAppState],
  );

  const enableToggleSidebar = useCallback(
    () => setAppState((state) => ({ ...state, sidebar: { ...state.sidebar, canToggle: true } })),
    [setAppState],
  );

  const disableToggleSidebar = useCallback(
    () => setAppState((state) => ({ ...state, sidebar: { ...state.sidebar, canToggle: false } })),
    [setAppState],
  );

  return {
    appState,
    expandSidebar,
    collapseSidebar,
    enableToggleSidebar,
    disableToggleSidebar,
  };
}
