import sindreSorhusCamelize from 'camelcase';
import sindreSorhusDecamelize from 'decamelize';

import { containsNumber, containsUuid } from 'utils/string';
import { isArray, isIterableObject } from 'utils/type-checks';

export function camelCase(str: string) {
  if (containsNumber(str) || containsUuid(str)) return str;

  function transform(str: string) {
    return sindreSorhusCamelize(str, { locale: false });
  }

  if (str.includes('.')) {
    return str.split('.').map(transform).join('.');
  }

  return transform(str);
}

export function camelCaseKeys<T extends Record<string, any> | any[]>(arg: T): T {
  return mapKeys(arg, camelCase) as T;
}

interface SnakeCaseOptions {
  separator?: string;
}

export function snakeCase(str: string, options?: SnakeCaseOptions) {
  if (containsNumber(str) || containsUuid(str)) return str;

  return sindreSorhusDecamelize(str, { separator: options?.separator || '_' });
}

export function snakeCaseKeys<T extends Record<string, any> | any[]>(arg: T, options?: SnakeCaseOptions): T {
  return mapKeys(arg, (key) => snakeCase(key, options)) as T;
}

function mapKeys(arg: Record<string, any> | any[], mapFn: (key: string) => string) {
  if (isArray(arg)) {
    return arg.map((value) => mapKeys(value, mapFn));
  }

  if (isIterableObject(arg)) {
    return Object.entries(arg).reduce((acc, [key, value]) => {
      const newKey = mapFn(key);
      const newValue = mapKeys(value, mapFn);
      acc[newKey] = newValue;

      return acc;
    }, {});
  }

  return arg;
}
