import { combineReducers } from 'redux';

import { activityReducer } from 'store/modules/activity';
import { entitiesReducer } from 'store/modules/entities/reducers';
import { errorsReducer } from 'store/modules/errors';
import { networkReducer } from 'store/modules/network';
import { successReducer } from 'store/modules/success';

export const reducer = combineReducers({
  activity: activityReducer,
  entities: entitiesReducer,
  errors: errorsReducer,
  network: networkReducer,
  success: successReducer,
});
