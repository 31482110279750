export function containsUuid(id: string) {
  const uuidRegex = new RegExp('[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}');

  return uuidRegex.test(id);
}

export function containsNumber(value: string) {
  const numberRegex = new RegExp('\\d{1,}');

  return numberRegex.test(value);
}

export function comparableString(str: string) {
  str = str.trim();
  str = str.toLowerCase();
  str = str.replace(/[.!?]+$/, '');
  str = removeDiacritics(str);

  return str;
}

// https://stackoverflow.com/a/37511463
export function removeDiacritics(string: string) {
  return string.normalize('NFKD').replace(/[\u0300-\u036f]/g, '');
}

export function matchText(text: string, match: string, options?: { exact?: boolean }) {
  const haystack = removeDiacritics(text.toLowerCase());
  const needle = removeDiacritics(match.toLowerCase());

  return options?.exact === true ? haystack === needle : haystack.includes(needle);
}

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#escaping
export function escapeRegex(string: string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}
