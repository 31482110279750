import { normalize } from 'normalizr';
import { Action } from 'redux';

import { getType } from 'utils/get-type';

// Action Creators
//======================================================================================================================

export function networkResponse({
  successType,
  requestDescription,
  data,
}: {
  successType: string;
  requestDescription: NetworkRequestDescription;
  data: any;
}): NetworkSuccessResponse {
  const response = requestDescription.normalizeSchema ? normalize(data, requestDescription.normalizeSchema) : data;
  const actionPayload = requestDescription.actionPayload || {};

  return {
    type: successType,
    payload: {
      ...actionPayload,
      request: requestDescription.params || requestDescription.payload,
      response,
    },
    error: false,
  };
}

// Reducer
//======================================================================================================================

type NetworkState = Record<string, boolean>;

type NetworkAction = Action<string>;

export const initialState = {};

export function networkReducer(state: NetworkState = initialState, action: NetworkAction): NetworkState {
  const { type } = action;

  switch (true) {
    case type.includes('REQUEST'): {
      return { ...state, [getType(type)]: true };
    }

    case type.includes('SUCCESS'):
    case type.includes('FAILURE'): {
      return { ...state, [getType(type)]: false };
    }

    default: {
      return state;
    }
  }
}
