import { ToastContainer } from 'react-toastify';

import { Portal } from 'components/portal';

export function ToastProvider() {
  return (
    <Portal>
      <ToastContainer position="bottom-right" draggable={false} />
    </Portal>
  );
}
