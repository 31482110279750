import { createContext, ReactNode, useCallback, useContext, useEffect, useRef, useState } from 'react';

import { useAuthenticator } from 'auth/authenticator';
import { retrieveToken, USER_TOKEN_STORAGE_KEY } from 'auth/token-storage';
import { AuthCredentials, UserToken } from 'auth/types';
import { authenticated, getJwtUserId } from 'auth/utils';

import { httpInterceptor } from 'api/client';

import { assert } from 'utils/assert';
import { Storage } from 'utils/storage';

interface AuthContextState {
  login: (credentials: AuthCredentials) => Promise<UserToken>;
  logout: () => Promise<void>;
  currentUserId: string | undefined;
  accessToken: string | null;
  isAuthenticated: boolean;
}

const AuthContext = createContext<AuthContextState | undefined>(undefined);

AuthContext.displayName = 'AuthContext';

export function AuthProvider({ children }: { children: ReactNode }) {
  const { userToken, login, logout } = useAuthSetup();

  const authValues = {
    login,
    logout,
    currentUserId: getJwtUserId(userToken),
    accessToken: userToken?.access || null,
    isAuthenticated: authenticated(userToken),
  };

  return <AuthContext.Provider value={authValues}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  const context = useContext(AuthContext);

  assert(context, 'The hook `useAuth` must be used within an `AuthProvider`.');

  return context;
}

function useAuthSetup() {
  const authenticator = useAuthenticator();
  const [userToken, setUserToken] = useState<UserToken>(retrieveToken());

  const interceptorInitiatedRef = useRef(false);
  if (!interceptorInitiatedRef.current) {
    const onSuccess = (token: UserToken) => {
      setUserToken((prevToken) => ({ ...prevToken, ...token }));
    };
    const onFailure = () => {
      goToLogin();
    };

    httpInterceptor(onSuccess, onFailure);

    interceptorInitiatedRef.current = true;
  }

  const login = useCallback(
    async (credentials: AuthCredentials) => {
      const token = await authenticator.login(credentials);
      setUserToken(token);

      return token;
    },
    [authenticator],
  );

  const logout = useCallback(async () => {
    await authenticator.logout();
    Storage.session.clear();
    goToLogin();
  }, [authenticator]);

  useEffect(() => {
    return Storage.local.listen(USER_TOKEN_STORAGE_KEY, (event) => {
      const token = event.newValue;
      if (token) {
        setUserToken(token);
      } else {
        goToLogin();
      }
    });
  }, []);

  return {
    userToken,
    login,
    logout,
  };
}

function goToLogin() {
  window.location.assign('/login');
}
